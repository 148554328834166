import { useGuardEvents } from '@/api/useGuards';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import LoadingScreen from '../LoadingScreen';
import ErrorMessage from '../ErrorMessage';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

interface Props {
  guard_id?: string;
}

const columnHelper = createColumnHelper<GuardEvent>();
const columns = [
  columnHelper.accessor('timestamp', { header: 'Timestamp' }),
  columnHelper.accessor('gateway_id', { header: 'Gateway' }),
  columnHelper.accessor('status', { header: 'Status' }),
  columnHelper.accessor('snr', { header: 'SNR' }),
  columnHelper.accessor('rssi', { header: 'RSSI' }),
  columnHelper.accessor('dr', { header: 'DataRate' }),
];

const GuardEvents: React.FC<Props> = ({ guard_id }) => {
  const { data: events, isLoading, error } = useGuardEvents(guard_id);

  const table = useReactTable({
    data: events || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to fetch guard events" error={error} />;
  }

  if (events === undefined) {
    return (
      <ErrorMessage
        title="Failed to fetch guard events"
        error="No guard events found"
      />
    );
  }

  return (
    <div className="flex grow flex-col gap-2">
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center gap-2 p-4">
        <button
          className="rounded border p-1 text-gray-800 disabled:bg-gray-200 disabled:text-gray-600"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <MdKeyboardArrowLeft />
        </button>
        <button
          className="rounded border p-1 text-gray-800 disabled:bg-gray-200 disabled:text-gray-600"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <MdKeyboardArrowRight />
        </button>
        Page
        <span className="font-bold">
          {table.getState().pagination.pageIndex + 1}
        </span>
        of
        <span className="font-bold">{table.getPageCount()}</span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => table.setPageSize(parseInt(e.target.value))}
        >
          <option>10</option>
          <option>20</option>
          <option>50</option>
        </select>
      </div>
    </div>
  );
};

export default GuardEvents;

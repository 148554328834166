import { useVersion } from '@/utils/useVersion';
import { useAuth0 } from '@auth0/auth0-react';
import { Transition } from '@headlessui/react';
import { Fragment, MouseEventHandler, useCallback, useMemo } from 'react';
import { MdClose } from 'react-icons/md';

interface Props {
  open?: boolean;
  onClose: () => void;
}

interface User {
  name?: string;
  'https://roxtec.com/company_name'?: string;
}

const Menu: React.FC<Props> = ({ open, onClose }) => {
  const { user, logout } = useAuth0<User>();
  const { data: version } = useVersion();

  const username = useMemo(() => user?.name ?? 'Not logged in', [user]);
  const company = useMemo(
    () => user?.['https://roxtec.com/company_name'] ?? '-',
    [user],
  );

  const handleClose = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (!open) return;

      e.stopPropagation();
      onClose();
    },
    [onClose, open],
  );

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-10 bg-black/25 transition-all"
              onClick={handleClose}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="transform translate-x-0"
            leaveTo="transform translate-x-full"
          >
            <div className="fixed inset-y-0 right-0 z-20 flex w-full max-w-[350px] flex-col divide-y divide-gray-200 bg-white shadow-lg text-gray-800">
              <div className="flex">
                <div className="flex flex-col gap-1 p-4">
                  <p className="font-bold">Client Version</p>
                  <p className="text-sm">{version}</p>
                </div>
                <div className="flex grow items-center justify-end p-4">
                  <button type="button" onClick={handleClose}>
                    <MdClose className="h-8 w-8" />
                  </button>
                </div>
              </div>
              <div className="flex grow flex-col p-4">
                <p className="text-sm text-gray-400">Logged in as</p>
                <p className="text-lg font-bold">{username}</p>
                <p className="text-sm">{company}</p>
              </div>
              <div className="flex flex-col gap-4 p-4">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    logout({
                      logoutParams: {
                        returnTo: window.location.origin + '/logout',
                      },
                    });
                  }}
                  className="navbar-button bg-error"
                >
                  Logout
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

export default Menu;

import { useCallback } from 'react';
import { useConfig, useConfigQuery } from './useConfig';
import { Auth0Provider } from '@auth0/auth0-react';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';

const App = () => {
  const { isLoading: isConfigLoading } = useConfigQuery();
  const { loaded: configLoaded, auth0 } = useConfig(
    useCallback(
      (state) => ({
        loaded: state.loaded,
        auth0: state.auth0,
      }),
      [],
    ),
  );

  if (isConfigLoading) {
    return null;
  }

  if (!configLoaded) {
    return <p>Failed to load configuration</p>;
  }

  return (
    <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientID}
      authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        audience: auth0.audience,
      }}
      cacheLocation="localstorage"
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  );
};

export default App;

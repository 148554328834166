import { AxiosError } from 'axios';
import React, { useMemo } from 'react';

interface Props {
  title: string;
  error: Error | string;
}

const ErrorMessage: React.FC<Props> = ({ title, error }) => {
  const message = useMemo<string>(() => {
    if (typeof error === 'string') {
      return error;
    }

    if (error instanceof AxiosError) {
      if (error.response?.data.message) {
        return error.response.data.message as string;
      }
    }

    return error.message;
  }, [error]);

  return (
    <div className="flex grow flex-col items-center justify-center gap-2 p-4">
      <h2 className="text-center">{title}</h2>
      {message && <p className="text-sm text-gray-400">{message}</p>}
    </div>
  );
};

export default ErrorMessage;

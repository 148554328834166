import { NavLink, Outlet } from 'react-router-dom';

const Asset: React.FC = () => {
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex gap-4 border-b border-gray-200 px-4">
        <NavLink to="gateways" className="navlink">
          Gateways
        </NavLink>
        <NavLink to="guards" className="navlink">
          Guards
        </NavLink>
      </div>
      <div className="overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Asset;

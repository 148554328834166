import Navbar from './Navbar';

const Layout: React.FC = ({ children }) => {
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <Navbar />
      <main className="flex flex-1 flex-col overflow-auto">{children}</main>
    </div>
  );
};

export default Layout;

import EmptyTransit from '@/images/EmptyTransit';

const LoadingScreen = () => (
  <div className="flex grow flex-col items-center justify-center gap-8 p-4">
    <h2 className="text-center">Loading...</h2>
    <EmptyTransit className="h-48 w-48 animate-twirl" />
  </div>
);

export default LoadingScreen;

import { Link, Navigate, createBrowserRouter } from 'react-router-dom';
import Root from './Root';
import ProtectedRoute from './ProtectedRoute';
import Customers from './Customers';
import PageNotFound from '@/PageNotFound';
import CustomerAssets from './CustomerAssets';
import CustomerName from '@/components/CustomerName';
import AssetName from '@/components/AssetName';
import Gateways from '@/views/Gateways/Gateways';
import Asset from '@/views/Asset';
import Gateway from '@/views/Gateways/Gateway';
import Guards from '@/views/Guard/Guards';
import GuardView from '@/views/Guard/Guard';
import Callback from '@/views/Callback';
import Logout from '@/views/Logout';

type RouteParam = 'customer_id' | 'asset_id' | 'gateway_id' | 'guard_id';

export type RouteProps = Partial<Record<RouteParam, string>>;

type RouteFn = (props?: RouteProps) => string;

const makeRoute =
  (path: string) =>
  (props?: RouteProps): string => {
    if (!props) {
      return path;
    }

    return Object.entries(props).reduce((acc, [key, value]) => {
      if (!value) return acc;

      return acc.replace(`:${key}`, value);
    }, path);
  };

export const route: Record<string, RouteFn> = {
  root: makeRoute('/'),
  logout: makeRoute('/logout'),
  callback: makeRoute('/callback'),
  customers: makeRoute('/customers'),
  customerDetails: makeRoute('/customers/:customer_id'),
  customerAssets: makeRoute('/customers/:customer_id/assets'),
  customerAsset: makeRoute('/customers/:customer_id/assets/:asset_id'),
  customerAssetGateways: makeRoute(
    '/customers/:customer_id/assets/:asset_id/gateways',
  ),
  customerAssetGateway: makeRoute(
    '/customers/:customer_id/assets/:asset_id/gateways/:gateway_id',
  ),
  customerAssetGuards: makeRoute(
    '/customers/:customer_id/assets/:asset_id/guards',
  ),
  customerAssetGuard: makeRoute(
    '/customers/:customer_id/assets/:asset_id/guards/:guard_id',
  ),
};

export const router = createBrowserRouter([
  {
    path: '/',
    handle: {
      crumb: () => (
        <Link to="/" className="breadcrumb">
          Home
        </Link>
      ),
    },
    element: <Root />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: route.customers(),
        handle: {
          crumb: () => (
            <Link to={route.customers()} className="breadcrumb">
              Customers
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Customers />,
          },
          {
            path: route.customerDetails(),
            handle: {
              crumb: (data: RouteProps) => (
                <Link to={route.customerDetails(data)} className="breadcrumb">
                  <CustomerName customer_id={data.customer_id} />
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: <Navigate to={'assets'} replace />,
              },
              {
                path: route.customerAssets(),
                handle: {
                  crumb: (data: RouteProps) => (
                    <Link
                      to={route.customerAssets(data)}
                      className="breadcrumb"
                    >
                      Assets
                    </Link>
                  ),
                },
                children: [
                  {
                    index: true,
                    element: <CustomerAssets />,
                  },
                  {
                    path: route.customerAsset(),
                    handle: {
                      crumb: (data: RouteProps) => (
                        <Link
                          to={route.customerAsset(data)}
                          className="breadcrumb"
                        >
                          <AssetName
                            customer_id={data?.customer_id}
                            asset_id={data?.asset_id}
                          />
                        </Link>
                      ),
                    },
                    element: <Asset />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to={'gateways'} replace />,
                      },
                      {
                        path: route.customerAssetGateways(),
                        handle: {
                          crumb: (data: RouteProps) => (
                            <Link
                              to={route.customerAssetGateways(data)}
                              className="breadcrumb"
                            >
                              Gateways
                            </Link>
                          ),
                        },
                        children: [
                          { index: true, element: <Gateways /> },
                          {
                            path: route.customerAssetGateway(),
                            element: <Gateway />,
                            handle: {
                              crumb: (data: RouteProps) => (
                                <Link
                                  to={route.customerAssetGateway(data)}
                                  className="breadcrumb"
                                >
                                  {data.gateway_id}
                                </Link>
                              ),
                            },
                          },
                        ],
                      },
                      {
                        path: route.customerAssetGuards(),
                        handle: {
                          crumb: (data: RouteProps) => (
                            <Link
                              to={route.customerAssetGuards(data)}
                              className="breadcrumb"
                            >
                              Guards
                            </Link>
                          ),
                        },
                        children: [
                          { index: true, element: <Guards /> },
                          {
                            path: route.customerAssetGuard(),
                            element: <GuardView />,
                            handle: {
                              crumb: (data: RouteProps) => (
                                <Link
                                  to={route.customerAssetGuard(data)}
                                  className="breadcrumb"
                                >
                                  {data.guard_id}
                                </Link>
                              ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: route.callback(), element: <Callback /> },
  { path: route.logout(), element: <Logout /> },
  { path: '/*', element: <PageNotFound /> },
]);

import { route } from '@/router';
import { useMemo, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import { NavLink, useMatches } from 'react-router-dom';
import Menu from './Menu';
import { useAuth0 } from '@auth0/auth0-react';

interface MatchHandle {
  crumb?: (data: any) => React.ReactNode;
}

const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  const matches = useMatches();
  const crumbs = useMemo<React.ReactNode[]>(
    () =>
      matches
        .filter((match) => Boolean((match.handle as MatchHandle)?.crumb))
        .map((match) => (match.handle as MatchHandle)?.crumb(match.params)),
    [matches],
  );

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex h-16 items-center bg-roxtec px-4 text-white">
        <NavLink to={route.root()}>
          <h2 className="text-lg font-bold">IoT-Admin</h2>
        </NavLink>
        <div className="grow" />
        {isAuthenticated && (
          <button
            className="text-button text-white"
            onClick={() => setMenuOpen(true)}
          >
            <MdMenu className="h-8 w-8" />
          </button>
        )}
        <Menu open={menuOpen} onClose={() => setMenuOpen(false)} />
      </div>
      <div className="p-4">
        <ol className="inline-flex items-center">
          {crumbs.map((crumb, i) => (
            <li key={i} className="inline-flex h-full items-center">
              {crumb}
              {i < crumbs.length - 1 && (
                <span className="mx-2 text-gray-500">/</span>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Navbar;

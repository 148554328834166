import { classNames } from '@/classNames';

interface ValueGroupProps {
  label: string;
  value?: React.ReactNode;
  className?: string;
}

const ValueGroup: React.FC<ValueGroupProps> = ({ label, value, className }) => {
  return (
    <div className={classNames('space-y-1', className)}>
      <h6 className="text-sm text-gray-400">{label}</h6>
      <p className="text-sm text-gray-900">{value}</p>
    </div>
  );
};

export default ValueGroup;

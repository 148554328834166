import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

interface VersionResponse {
  version?: string;
}

export const useVersion = (onVersionUpdated?: (version: string) => void) => {
  return useQuery<string | undefined, AxiosError>({
    queryKey: ['version'],
    queryFn: () =>
      axios
        .get<VersionResponse>('/version.json')
        .then((res) => res.data.version),
    refetchInterval: 1000 * 60 * 10,
    structuralSharing: (prev, next) => {
      if (onVersionUpdated && prev && next && prev !== next) {
        onVersionUpdated(next);
      }

      if (prev !== next) {
        return next;
      }
      return prev;
    },
  });
};

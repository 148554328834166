import { useGuardsByAssetId } from '@/api/useGuards';
import ErrorMessage from '@/components/ErrorMessage';
import LoadingScreen from '@/components/LoadingScreen';
import TransitName from '@/components/TransitName';
import { RouteProps } from '@/router';
import { formatDateString } from '@/utils/time';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const columnHelper = createColumnHelper<Guard>();
const Guards = () => {
  const navigate = useNavigate();
  const { customer_id, asset_id } = useParams<RouteProps>();
  const { data, isLoading, error } = useGuardsByAssetId(asset_id);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', { header: 'ID' }),
      columnHelper.accessor('placement.transit_id', {
        header: 'Transit',
        cell: (info) => (
          <TransitName
            customerId={customer_id}
            assetId={asset_id}
            transitId={info.getValue()}
          />
        ),
      }),
      columnHelper.accessor('placement.opening_id', { header: 'Opening' }),
      columnHelper.accessor('status', { header: 'Status' }),
      columnHelper.accessor('timestamp', {
        header: 'Last seen',
        cell: (info) => formatDateString(info.getValue()),
      }),
    ],
    [asset_id, customer_id],
  );

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleGuardClick = useCallback(
    (guardId: string) => {
      navigate(guardId);
    },
    [navigate],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to fetch guards" error={error} />;
  }

  return (
    <div className="m-4 rounded bg-white p-4 shadow">
      <table className="table w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer"
              onClick={() => handleGuardClick(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={table.getAllColumns().length}>No guards found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Guards;

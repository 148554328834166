import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const Callback = () => {
  const { isAuthenticated, error } = useAuth0();

  if (error) {
    return <p>An error occured when setting up the session: {error.message}</p>;
  }

  if (isAuthenticated) {
    // Redirect to the home page if the user is authenticated.
    return <Navigate to="/" />;
  }

  return <p>Setting up session...</p>;
};

export default Callback;

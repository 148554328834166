import { useNavigate } from 'react-router-dom';
import Navbar from '@/components/Navbar';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex grow flex-col">
      <Navbar />
      <p className="p-4 text-center text-lg">You have been logged out</p>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            navigate('/');
          }}
          className="navbar-button m-4 bg-roxtec px-12"
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default Logout;

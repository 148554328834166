import React from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { classNames } from '@/classNames';
import Spinner from '../Spinner';

interface Props {
  value: string;
  onChange: (search_string: string) => void;
  placeholder?: string;
  loading?: boolean;
  className?: string;
}

const Search: React.FC<Props> = ({
  value,
  onChange,
  placeholder = 'Search',
  loading,
  className,
}) => {
  return (
    <div className={classNames('relative rounded-md shadow-sm', className)}>
      <div className="pointer-events-none absolute inset-y-0 left-0 my-2 flex items-center border-r">
        <FaMagnifyingGlass
          className="mx-2 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </div>
      <input
        name="search"
        id="search"
        className="input pl-12"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {loading && (
        <div className="pointer-events-none absolute inset-y-0 right-0 my-2 flex items-center">
          <Spinner className="mx-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export default Search;

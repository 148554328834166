import { useConfig } from '@/useConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

export const useGuardsByAssetId = (assetId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Guard[], AxiosError>({
    queryKey: ['assets', assetId, 'guards'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Guard[]>(
        `${api_base_url}/guards/by_asset/${assetId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    },
    enabled: !!assetId,
  });
};

export const useGuard = (guardId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Guard, AxiosError>({
    queryKey: ['guards', guardId],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Guard>(
        `${api_base_url}/guards/${guardId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    },
    enabled: !!guardId,
  });
};

export const useGuardEvents = (guardId?: string, limit = 500) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<GuardEvent[], AxiosError>({
    queryKey: ['guards', guardId, 'events', { limit }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<GuardEvent[]>(
        `${api_base_url}/guards/${guardId ?? ''}/events`,
        {
          params: {
            limit,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    },
    enabled: !!guardId,
  });
};

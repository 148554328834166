import { useCustomer } from '@/api/useCustomers';

interface Props {
  customer_id?: string;
}

const CustomerName: React.FC<Props> = ({ customer_id }) => {
  const { data: customer, isLoading } = useCustomer(customer_id);

  if (!customer_id) return null;

  if (isLoading) {
    return (
      <span className="inline-block h-4 w-36 animate-pulse rounded bg-gray-200" />
    );
  }

  if (!customer) return <>{customer_id}</>;

  return <>{customer.name}</>;
};

export default CustomerName;

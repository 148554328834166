import { useGateway } from '@/api/useGateways';
import ErrorMessage from '@/components/ErrorMessage';
import LoadingScreen from '@/components/LoadingScreen';
import GatewayEvents from '@/components/Tables/GatewayEvents';
import ValueGroup from '@/components/ValueGroup';
import { RouteProps } from '@/router';
import { formatDateString, uptimeToString } from '@/utils/time';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const Gateway = () => {
  const { gateway_id } = useParams<RouteProps>();
  const { data: gateway, isLoading, error } = useGateway(gateway_id);
  const uptime = useMemo(
    () => uptimeToString(gateway?.uptime),
    [gateway?.uptime],
  );

  const lastSeen = useMemo(
    () => formatDateString(gateway?.timestamp),
    [gateway?.timestamp],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to fetch gateway" error={error} />;
  }

  if (!gateway) {
    return (
      <ErrorMessage title="Failed to fetch gateway" error="No gateway found" />
    );
  }

  return (
    <div className="flex h-full flex-col items-start gap-4 p-4 md:flex-row">
      <div className="grid grid-cols-2 gap-4 rounded bg-white p-4 shadow">
        <ValueGroup label="ID" value={gateway.id} />
        <ValueGroup label="Firmware" value={gateway.firmware} />
        <ValueGroup label="Product ID" value={gateway.product_id} />
        <ValueGroup label="Uptime" value={uptime} />
        <ValueGroup label="IMSI" value={gateway.imsi} />
        <ValueGroup label="Eth0 IP" value={gateway.eth0_ip} />
        <ValueGroup label="PPP IP" value={gateway.ppp_ip} />
        <ValueGroup label="PPP RSSI" value={gateway.ppp_rssi} />
        <ValueGroup label="Last seen" value={lastSeen} className="col-span-2" />
      </div>
      <div className="flex flex-1 flex-col space-y-4 rounded bg-white shadow">
        <div className="flex items-center gap-4 p-4">
          <h2 className="header2 grow">Log</h2>
        </div>
        <GatewayEvents gateway_id={gateway_id} />
      </div>
    </div>
  );
};

export default Gateway;

import { useGatewaysByAssetId } from '@/api/useGateways';
import ErrorMessage from '@/components/ErrorMessage';
import LoadingScreen from '@/components/LoadingScreen';
import { RouteProps } from '@/router';
import { formatDateString, uptimeToString } from '@/utils/time';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const columnHelper = createColumnHelper<Gateway>();
const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('firmware', {
    header: 'Firmware',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('uptime', {
    header: 'Uptime',
    cell: (info) => uptimeToString(info.getValue()),
  }),
  columnHelper.accessor('timestamp', {
    header: 'Last seen',
    cell: (info) => formatDateString(info.getValue()),
  }),
];

const Gateways = () => {
  const navigate = useNavigate();

  const { asset_id } = useParams<RouteProps>();
  const { data, error, isLoading } = useGatewaysByAssetId(asset_id);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = useCallback(
    (gatewayId: string) => {
      navigate(gatewayId);
    },
    [navigate],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to fetch gateway list" error={error} />;
  }

  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col gap-4 rounded bg-white p-4 shadow">
        <table className="table min-w-full">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="cursor-pointer"
                onClick={() => handleRowClick(row.original.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  No gateways found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Gateways;

import { useCustomers } from '@/api/useCustomers';
import List from '@/components/List/List';
import ListItem from '@/components/List/ListItem';
import Search from '@/components/Search';
import SortButton, { Sort, sortEntities } from '@/components/SortButton';
import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { MdBusiness, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import FuseResult = Fuse.FuseResult;
import { route } from '.';

const sortKeys: (keyof Customer)[] = ['name', 'consortium'];

const Customers = () => {
  const [sort, setSort] = useState<Sort<Customer>>({
    key: 'name',
    direction: 'ascending',
  });
  const { data: customers, isLoading, isFetched } = useCustomers();
  const [search, setSearch] = useState('');

  const fuse = useMemo(() => new Fuse<Customer>([], { keys: sortKeys }), []);
  useEffect(() => {
    if (customers) {
      fuse.setCollection(customers);
    }
  }, [fuse, customers]);

  const filteredCustomers = useMemo<Customer[]>(() => {
    if (!search) {
      if (!customers) return [];

      return sortEntities(customers, sort);
    }
    return fuse.search(search).map((r: FuseResult<Customer>) => r.item);
  }, [customers, fuse, search, sort]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex gap-4">
        <Search
          className="max-w-[500px] grow"
          value={search}
          onChange={setSearch}
          loading={isLoading}
        />
        <SortButton sort={sort} onChange={setSort} keys={sortKeys} />
      </div>
      <List isLoading={isLoading} isFetched={isFetched}>
        {filteredCustomers?.map((customer) => (
          <Link
            key={customer.id}
            to={route.customerAssets({ customer_id: customer.id })}
          >
            <ListItem
              title={customer.name}
              subtitle={customer.consortium}
              startIcon={<MdBusiness className="h-6 w-6" />}
              endIcon={<MdKeyboardArrowRight className="h-8 w-8" />}
            />
          </Link>
        ))}
      </List>
    </div>
  );
};

export default Customers;

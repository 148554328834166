import { useGuard } from '@/api/useGuards';
import ErrorMessage from '@/components/ErrorMessage';
import LoadingScreen from '@/components/LoadingScreen';
import GuardEvents from '@/components/Tables/GuardEvents';
import TransitName from '@/components/TransitName';
import ValueGroup from '@/components/ValueGroup';
import { RouteProps } from '@/router';
import { formatDateString } from '@/utils/time';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const GuardView = () => {
  const { guard_id } = useParams<RouteProps>();
  const { data: guard, isLoading, error } = useGuard(guard_id);

  const lastSeen = useMemo(
    () => formatDateString(guard?.timestamp),
    [guard?.timestamp],
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to fetch guard" error={error} />;
  }

  if (!guard) {
    return (
      <ErrorMessage title="Failed to fetch guard" error="No guard found" />
    );
  }

  return (
    <div className="flex h-full flex-col items-start gap-4 p-4 md:flex-row">
      <div className="grid grid-cols-2 gap-4 rounded bg-white p-4 shadow">
        <ValueGroup label="ID" value={guard.id} />
        <ValueGroup label="Gateway" value={guard.gateway_id} />
        <ValueGroup
          label="Transit"
          value={
            <TransitName
              customerId={guard.placement?.customer_id}
              assetId={guard.placement?.asset_id}
              transitId={guard.placement?.transit_id}
            />
          }
        />
        <ValueGroup label="Opening" value={guard.placement?.opening_id} />
        <ValueGroup label="Status" value={guard.status} />
        <ValueGroup label="SNR" value={guard.snr} />
        <ValueGroup label="RSSI" value={guard.rssi} />
        <ValueGroup label="DR" value={guard.dr} />
        <ValueGroup label="Last seen" value={lastSeen} className="col-span-2" />
      </div>
      <div className="flex flex-1 flex-col space-y-4 rounded bg-white shadow">
        <div className="flex items-center gap-4 p-4">
          <h2 className="header2 grow">Log</h2>
        </div>
        <GuardEvents guard_id={guard_id} />
      </div>
    </div>
  );
};

export default GuardView;

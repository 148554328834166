import { useCustomerAssetTransits } from '@/api/useAssets';
import { useMemo } from 'react';

interface Props {
  customerId?: string;
  assetId?: string;
  transitId?: string;
}

const TransitName: React.FC<Props> = ({ customerId, assetId, transitId }) => {
  const { data, isLoading } = useCustomerAssetTransits(customerId, assetId);
  const transit = useMemo(
    () => data?.find((transit) => transit.id === transitId),
    [data, transitId],
  );

  if (!customerId || !assetId || !transitId) {
    return null;
  }

  if (isLoading) {
    return <div className="h-5 w-32 animate-pulse rounded bg-gray-200" />;
  }

  if (!transit) {
    return <>{transitId}</>;
  }

  return <>{transit.name}</>;
};

export default TransitName;

import { DateTime, Duration } from 'luxon';

export const uptimeToString = (seconds?: number) => {
  if (seconds === undefined) {
    return '';
  }

  const duration = Duration.fromObject({
    seconds: Math.round(seconds),
  })
    .normalize()
    .rescale();

  return duration.toHuman({
    unitDisplay: 'narrow',
  });
};

export const formatDateString = (date?: string) => {
  if (!date) {
    return '';
  }

  return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm:ss');
};

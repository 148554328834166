import { useConfig } from '@/useConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useGuardsByAssetId } from './useGuards';

export const useGateway = (gatewayId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Gateway, AxiosError>({
    queryKey: ['gateways', gatewayId],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Gateway>(
        `${api_base_url}/gateways/${gatewayId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!gatewayId,
  });
};

export const useGatewayEvents = (gatewayId?: string, limit = 500) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<GatewayEvent[], AxiosError>({
    queryKey: ['gateways', gatewayId, 'events', { limit }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<GatewayEvent[]>(
        `${api_base_url}/gateways/${gatewayId ?? ''}/events?limit=500`,
        {
          params: {
            limit,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!gatewayId,
  });
};

export const useGateways = (gatewayIds: string[]) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Gateway[], AxiosError>({
    queryKey: ['gateways', gatewayIds],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Gateway[]>(
        `${api_base_url}/gateways?ids=${gatewayIds.join(',')}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!gatewayIds && gatewayIds.length > 0,
  });
};

export const useGatewaysByAssetId = (assetId?: string) => {
  const { data: guards, isLoading: isGuardsLoading } =
    useGuardsByAssetId(assetId);

  const gatewayIds = useMemo(
    () =>
      guards?.reduce((acc, guard) => {
        if (guard.gateway_id && !acc.includes(guard.gateway_id)) {
          acc.push(guard.gateway_id);
        }
        return acc;
      }, [] as string[]) || [],
    [guards],
  );
  const result = useGateways(gatewayIds);

  const isLoading = useMemo(() => {
    if (isGuardsLoading) return true;

    if (guards?.length === 0) return false;

    return result.isLoading;
  }, [guards?.length, isGuardsLoading, result.isLoading]);

  return { ...result, isLoading };
};

import { useCustomerAsset } from '@/api/useAssets';

interface Props {
  customer_id?: string;
  asset_id?: string;
}

const AssetName: React.FC<Props> = ({ customer_id, asset_id }) => {
  const { data: asset, isLoading } = useCustomerAsset(customer_id, asset_id);

  if (!asset_id) return null;

  if (isLoading) {
    return (
      <span className="inline-block h-4 w-36 animate-pulse rounded bg-gray-200" />
    );
  }

  if (!asset) return <>{asset_id}</>;

  return <>{asset.name}</>;
};

export default AssetName;

import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { route } from '.';
import Layout from '@/components/Layout';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    console.log('Not authenticated, redirecting to home');
    return <Navigate to={route.root()} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoute;
